import { SocialProvider } from './../authentication-types';
import { ApiRequest, Environment } from '../../../common';
import { SosCryptoService } from '../../../helpers';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

export abstract class BaseSocialAuth {
    abstract authPaths: {
        login: string;
    };

    constructor(
        public cryptoService: SosCryptoService,
        public env: Environment,
        public translate: TranslateService
    ) {}

    createState(): string {
        const redirectUrl = window.location.origin + this.authPaths.login;

        return this.cryptoService.encryptData(
            {
                redirect: redirectUrl,
            },
            this.env.cookieKey
        );
    }

    abstract beginAuth(
        socialProvider: SocialProvider
    ): Promise<ApiRequest<any>>;

    unexpectedErrorWhenAuthorize(socialProvide: SocialProvider) {
        const response = {
            error: [
                {
                    code: 100603,
                    message: `Unable to authorize with ${socialProvide.type}. Please try again later or use a different method.`,
                },
            ],
        }

        return response;
    }
}
